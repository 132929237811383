// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap/dist/js/bootstrap';

// Set application locale
window.locale = document.querySelector('html').lang;

// simple-datatables initialization and implementation
import {DataTable} from 'simple-datatables';

$(document).on('turbolinks:load', () => {
  $(document).ready(function(){
    if($("table[data-behavior='table']").length != 0){
      let labels = {
        sq: {
          placeholder: "Kërko...",
          perPage: "{select} të dhëna për faqe",
          noRows: "Nuk është gjetur asnjë e dhënë",
          info: "Duke shfaqur {start} deri {end} prej gjithsej {rows} rezultateve ({page}/{pages})",
        },
        en: {
          placeholder: "Search...",
          perPage: "Show {select} records per page",
          noRows: "No records to display",
          info: "Showing {start} to {end} of {rows} records ({page}/{pages})",
        },
      };

      let dataTable = new DataTable("table[data-behavior=table]", {
        labels: labels[window.locale],
  			layout: {
			    top: "{info}{search}",
			    bottom: "{select}{pager}"
  			},
      });
    }
  });
});

// flatpickr initialization and implementation
import flatpickr from "flatpickr";
import { Albanian } from "flatpickr/dist/l10n/sq.js"

function flatpickrLanguage(){
  if(window.locale == 'sq')
    return Albanian;
  else
    return null;
}

$(document).on('turbolinks:load', () => {
  $(document).ready(function(){
    if($("[data-behavior='datepicker']").length != 0){
      flatpickr("[data-behavior='datepicker']", {
        altInput: true,
        altFormat: 'j F Y',
        dateFormat: 'Y-m-d',
        locale: flatpickrLanguage(),
        disableMobile: "false",
      });
    }

    if($("[data-behavior='timepicker']").length != 0){
      flatpickr("[data-behavior='timepicker']", {
        enableTime: true,
        noCalendar: true,
        altInput: true,
        altFormat: 'H:i',
        dateFormat: "Y-m-d H:i:S \\UTC",
        locale: flatpickrLanguage(),
        disableMobile: "false",
      });
    }

    if($("[data-behavior='datetimepicker']").length != 0){
      flatpickr("[data-behavior='datetimepicker']", {
        enableTime: true,
        altInput: true,
        altFormat: 'j F Y',
        dateFormat: "Y-m-d H:i:S \\UTC",
        locale: flatpickrLanguage(),
        disableMobile: "false",
      });
    }
  });
});

// fullcalendar initialization and implementation
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import sqFullcalendarLocale from '@fullcalendar/core/locales/sq';

$(document).on('turbolinks:load', () => {
  $(document).ready(function(){

    if($("[data-behavior='fullcalendar']").length != 0){
      let calendarEl = $("[data-behavior='fullcalendar']")[0];

      let calendar = new Calendar(calendarEl, {
        plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, momentPlugin ],
        titleFormat: 'D MMM Y',
        timeZone: 'UTC',
        columnHeaderFormat: 'dddd',
        slotLabelFormat: 'HH:mm',
        listDayFormat: 'dddd',
        listDayAltFormat: 'D MMMM, YYYY',
        dayPopoverFormat: 'D MMMM, YYYY',
        eventTimeFormat: 'HH:mm',
        selectable: true,
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        navLinks: true, // can click day/week names to navigate views
        editable: true,
        eventLimit: true, // allow "more" link when too many events
        events: {
          url: `/daily_activities/calendar_events?locale=${window.locale}`,
          method: 'GET',
          extraParams: {
            group_id: $("#group_id").val(),
          },
          failure: function() {
            alert('Ndodhi një gabim gjatë shfaqjes së kalendarit.');
          },
          color: 'yellow',
          textColor: 'black',
        }
      });

      if(window.locale == 'sq')
        calendar.setOption('locale', sqFullcalendarLocale);

      calendar.render();

      $("#group_id").on("change", function(){
        let eventSources = calendar.getEventSources();
        
        eventSources.forEach(function(eventSource){
          eventSource.remove();
        });

        calendar.addEventSource({
          url: '/daily_activities/calendar_events',
          method: 'GET',
          extraParams: {
            group_id: $("#group_id").val(),
          },
          failure: function() {
            alert('Ndodhi një gabim gjatë shfaqjes së kalendarit.');
          },
          color: 'yellow',
          textColor: 'black',
        });
      });
    }
  });
});

// alert any rails-ujs ajax errors with an error message
$(document).on('turbolinks:load', () => {
  $(document).ready(function(){
    document.body.addEventListener('ajax:error', function(event) {
      alert("Ndodhi një gabim gjatë procesimit të kërkesës suaj.");
    });
  });
});

// enable smooth scrolling on selected bookmarked anchor tags
$(document).on('click', 'a[href^="#"].smooth-scroll', 
  function (event) {
    event.preventDefault();

    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
  }
);

// show the progress of a direct upload
import './direct_uploads';

// set custom boostrap file label after files are attached
$(document).on('turbolinks:load', () => {
  $(document).ready(function(){
    $('input[type=file].custom-file-input').on("change",function() {
      var label = $(this).next('label');
      var filesAttached = $(this)[0].files.length;
      var multipleFiles = filesAttached != 1;
      
      var content = { sq: ' foto janë bashkëngjitur', en: ' photos attached' }

      if(multipleFiles){
        label.text(filesAttached + content[window.locale])
      }
      else{
        var fileName = $(this)[0].files[0].name;
        label.text(fileName);
      }
    });
  });
});

// // select2 initialization and implementation
// // after yarn add select2; in terminal
// // after @import '~select2/dist/css/select2'; in styles.scss
// import $ from 'jquery';
// import 'select2';

// $(document).on('turbolinks:load', () => {
//   $(document).ready(function(){
//     if($("[data-behavior='select']").length != 0){
//       $("[data-behavior='select']").select2();
//     }
//   });
// });

// back button behavior implementation using PushState
// $(document).on('turbolinks:load', () => {
//   $(document).ready(function(){
//     if($("[data-behavior='back']").length != 0){
//       $("[data-behavior='back']").click(function(event){
//         event.preventDefault();
//         window.history.back();
//       });
//     }
//   });
// });